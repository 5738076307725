import React, { useEffect, useState, useRef } from 'react';
import { getGlosotong, fsdownloadGlosotongFile } from './ControlConstCode';
import classNames from 'classnames';


const FileUpload = ({
    glosori_id,
    uniqueId,
    fileInfo,
    selectedFile,
    fileType,
    videoFsid,
    audioFsid,
    severFileType,
    handleFileChange,
    handleFileUploadClick,
    handleDropFileChange,
    fileInputRef,
    language,
    tolanguage,
    originalContent,
    translatedContent,
    isSynthesisComplete,
    setIsSynthesisComplete
}) => {

    // 미디어 파일 URL을 상태로 관리하여 반복적인 요청 방지
    const [localMediaUrl, setLocalMediaUrl] = useState(null);
    const [videoUrl, setVideoUrl] = useState('');
    const [audioUrl, setAudioUrl] = useState('');
    const [synthesisAudioFsid, setSynthesisAudioFsid] = useState('');
    const [synthesisAudioUrl, setSynthesisAudioUrl] = useState('');
    const videoRef = useRef(null);
    const audioRef = useRef(null);
    const [currentAudioUrl, setCurrentAudioUrl] = useState(audioUrl);
    const [audioLanguage, setAudioLanguage] = useState('original')
    const [dragActive, setDragActive] = useState(false); //드롭 영역 스타일을 변경하여 사용자에게 드래그 중임을 알림
    const [isCollapsed, setIsCollapsed] = useState(false); //내용 접기 상태 관리

    useEffect(() => {
        if (selectedFile) {
            const selectedFileUrl = URL.createObjectURL(selectedFile);
            setLocalMediaUrl(selectedFileUrl);
            return () => {
                URL.revokeObjectURL(selectedFileUrl);
            };
        } else {
            setLocalMediaUrl(null); // 선택된 파일이 없을 때 미디어 URL 초기화
        }
    }, [selectedFile]);

    useEffect(() => {
        const fetchMediaUrls = async () => {
            try {
                if (severFileType === 'video') {
                    const videoBlob = await fsdownloadGlosotongFile(glosori_id, videoFsid);
                    const videoUrl = URL.createObjectURL(videoBlob);
                    setVideoUrl(videoUrl);

                    const audioBlob = await fsdownloadGlosotongFile(glosori_id, audioFsid);
                    const audioUrl = URL.createObjectURL(audioBlob);
                    setAudioUrl(audioUrl);

                    if (translatedContent[1].fsid) {
                        // console.log('합성음성 존재')
                        const synthesisFileForFsid = await getGlosotong(glosori_id, uniqueId);
                        const synthesisFsid = synthesisFileForFsid[`stt_${tolanguage}`].fs_id;
                        if (synthesisFsid) {
                            const synthesisAudioBlob = await fsdownloadGlosotongFile(glosori_id, synthesisFsid);
                            setSynthesisAudioUrl(URL.createObjectURL(synthesisAudioBlob));
                        }
                    }
                } else {
                    const audioBlob = await fsdownloadGlosotongFile(glosori_id, audioFsid);
                    const audioUrl = URL.createObjectURL(audioBlob);
                    setAudioUrl(audioUrl);
                }
            } catch (error) {
                console.error('미디어 URL을 가져오는 중 오류 발생:', error);
            }
        };

        // originalContent가 있을 때만 파일 다운로드 수행
        if (originalContent.length > 0) {
            fetchMediaUrls();
        } else {
            // originalContent가 없을 경우 URL 초기화
            setVideoUrl(null);
            setAudioUrl(null);
            setSynthesisAudioUrl(null);
        }
    }, [videoFsid, audioFsid, glosori_id, uniqueId, language, tolanguage, originalContent, translatedContent]);

    useEffect(() => {
        // 비디오와 오디오의 재생 시간을 동기화하는 함수
        const syncAudioWithVideo = () => {
            if (audioRef.current && videoRef.current) {
                audioRef.current.currentTime = videoRef.current.currentTime;
            }
        };

        const handlePause = () => {
            if (audioRef.current) {
                audioRef.current.pause();
            }
        };

        const handlePlay = () => {
            if (audioRef.current) {
                audioRef.current.play();
            }
        };

        // timeupdate 이벤트로 동기화
        if (videoRef.current) {
            videoRef.current.addEventListener('timeupdate', syncAudioWithVideo);
            videoRef.current.addEventListener('pause', handlePause);
            videoRef.current.addEventListener('play', handlePlay);
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('timeupdate', syncAudioWithVideo);
                videoRef.current.removeEventListener('pause', handlePause);
                videoRef.current.removeEventListener('play', handlePlay);
            }
        };
    }, [audioUrl, videoUrl]);

    // 음성언어 변경 함수
    const changeAudioLanguage = (newLanguage) => {
        if (audioLanguage !== newLanguage) {
            // audioRef와 videoRef의 current 객체가 존재하는지 확인
            if (!videoRef.current) {
                console.error("videoRef.current가 null입니다.");
                return;
            }
            if (!audioRef.current) {
                console.error("audioRef.current가 null입니다.");
                return;
            }

            // 비디오 파일이 있는지 확인
            const currentTime = videoRef.current ? videoRef.current.currentTime : audioRef.current.currentTime;
            const wasPlaying = videoRef.current ? !videoRef.current.paused : !audioRef.current.paused;

            // 언어 변경에 따라 오디오 트랙 변경
            let newAudioUrl = '';
            if (newLanguage === 'original') {
                newAudioUrl = audioUrl;
            } else if (newLanguage === 'synthesis') {
                newAudioUrl = synthesisAudioUrl;
            }

            // 새로운 오디오 URL이 유효하지 않으면 재생 중지
            if (!newAudioUrl) {
                console.error("해당 언어에 대한 오디오 URL이 없습니다.");
                return;
            }

            audioRef.current.src = newAudioUrl;
            audioRef.current.currentTime = currentTime;

            if (wasPlaying) {
                audioRef.current.play().catch((error) => console.error('오디오 재생 중 오류 발생:', error));
            }

            setAudioLanguage(newLanguage);
        }
    };


    const handleDrag = (e) => {
        e.preventDefault(); // 기본 동작 취소
        e.stopPropagation(); // 이벤트 전파 중단
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleDropFileChange(e.dataTransfer.files);
        }
    };

    useEffect(() => {
        // 드래그 앤 드롭 이벤트를 방지하기 위한 핸들러
        const preventDefault = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };

        // 모든 드래그 앤 드롭 이벤트에 핸들러 등록
        document.addEventListener('dragenter', preventDefault, false);
        document.addEventListener('dragover', preventDefault, false);
        document.addEventListener('dragleave', preventDefault, false);
        document.addEventListener('drop', preventDefault, false);

        return () => {
            // 컴포넌트 언마운트 시 핸들러 제거
            document.removeEventListener('dragenter', preventDefault, false);
            document.removeEventListener('dragover', preventDefault, false);
            document.removeEventListener('dragleave', preventDefault, false);
            document.removeEventListener('drop', preventDefault, false);
        };
    }, []);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    }

    return (
        <div className='flex flex-col flex-auto w-full h-full bg-indigo-100 relative'
            // style={{minHeight: selectedFile ? 'auto' : '90vh' }}>       
            style={{ minHeight: selectedFile ? 'auto' : '30vh' }}>
            <div
                // className='flex flex-col-reverse md:flex-row flex-auto justify-center items-center max-w-full mx-4 md:mx-12 my-4 rounded-lg bg-white relative'
                className={classNames('flex flex-col-reverse md:flex-row flex-auto justify-center items-center max-w-full mx-4 md:mx-12 my-4 rounded-lg relative bg-white', {
                    'border-2 border-dashed border-indigo-500': dragActive,
                    'border-none': !dragActive
                })}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
            >

                {selectedFile ? (
                    <>
                        {/* 파일 정보 및 라디오 버튼 섹션 */}
                        <div className="flex flex-col md:flex-row w-full md:w-1/3 p-2 order-2 md:order-1">
                            {/* <div className='flex flex-auto items-start justify-center  max-w-full md:w-1/3 order-2 md:order-1'> */}
                            <div className='flex flex-col w-full h-full '>
                                <div className='text-left w-full my-2 p-4 border border-slate-200 rounded-lg flex-grow'>
                                    <div className="relative w-full flex items-center border border-gray-300 rounded-lg">
                                        <input
                                            className="absolute inset-0 opacity-0 cursor-pointer"
                                            id="file_input"
                                            type="file"
                                            accept="audio/*,video/*"
                                            onChange={handleFileChange}
                                        />
                                        <button
                                            className="w-20 h-full text-sm inline-flex items-center px-2 py-2 text-white bg-indigo-700 rounded-tl-lg rounded-bl-lg hover:bg-indigo-400 focus:outline-none"
                                            onClick={() => document.getElementById('file_input').click()}
                                        >
                                            파일 선택
                                        </button>
                                        <span className="flex-1 ml-4 text-sm font-bold text-gray-900 dark:text-gray-400 break-words">{fileInfo.name}</span>
                                    </div>

                                    {!isCollapsed && (
                                        <div class="relative w-full overflow-x-auto pt-2 sm:rounded-lg">
                                            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                                <tbody>
                                                    <tr class="">
                                                        <th scope="row" class="w-28 px-3 py-1 font-medium text-gray-900 bg-gray-100 dark:text-white dark:bg-gray-800 rounded-tl-lg border-b border-white">
                                                            파일크기
                                                        </th>
                                                        <td class="px-4 py-2 rounded-tr-lg">
                                                            {fileInfo.size} MB
                                                        </td>
                                                    </tr>
                                                    <tr class="">
                                                        <th scope="row" class="px-3 py-1 font-medium text-gray-900 bg-gray-100 dark:text-white dark:bg-gray-800 border-b border-white">
                                                            길이
                                                        </th>
                                                        <td class="px-4 py-2">
                                                            {fileInfo.duration} seconds
                                                        </td>
                                                    </tr>
                                                    <tr class="">
                                                        <th scope="row" class="px-3 py-1 font-medium text-gray-900 bg-gray-100 dark:text-white dark:bg-gray-800 border-b border-white">
                                                            채널 수
                                                        </th>
                                                        <td class="px-4 py-2">
                                                            {fileInfo.numberOfChannels}
                                                        </td>
                                                    </tr>
                                                    <tr class="">
                                                        <th scope="row" class="px-3 py-1 font-medium text-gray-900 bg-gray-100 dark:text-white dark:bg-gray-800 border-b border-white">
                                                            샘플링레이트
                                                        </th>
                                                        <td class="px-4 py-2">
                                                            {fileInfo.sampleRate} Hz
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                                {/* <div className="flex w-full items-center justify-center my-1 border border-gray-200 rounded dark:border-gray-700"> */}
                                <div className="m-2 mx-0 border border-gray-200 rounded dark:border-gray-700 p-2">
                                    <ul className="space-y-1 md:space-y-0 md:flex md:space-x-2">
                                        <li className='w-full'>
                                            <div
                                                className="flex w-full items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                                                onClick={() => changeAudioLanguage('original')}
                                            >
                                                <input
                                                    id="original-audio"
                                                    type="radio"
                                                    value="original_audio"
                                                    name="audio-option"
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                                />
                                                <label htmlFor="original-audio" className="w-full ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    원본 음성
                                                </label>
                                            </div>
                                        </li>
                                        <li className='w-full'>
                                            <div
                                                // className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600" 
                                                className={classNames(
                                                    "flex items-center p-2 rounded",
                                                    {
                                                        "hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer": isSynthesisComplete,
                                                        "cursor-not-allowed": !isSynthesisComplete,
                                                    }
                                                )}
                                                onClick={() => changeAudioLanguage('synthesis')}
                                            >
                                                <input
                                                    id="synthesis-audio"
                                                    type="radio"
                                                    value="synthesis_audio"
                                                    name="audio-option"
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                                    disabled={!isSynthesisComplete}
                                                />
                                                <label htmlFor="synthesis-audio" className="w-full ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    합성 음성
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* 미디어 재생 섹션 */}
                        <div className="flex flex-col w-full md:w-2/3 p-2 order-1 md:order-2">
                            {/* <div className="flex items-center justify-center w-full md:w-2/3 m-8 order-1 md:order-2"> */}
                            {audioUrl || videoUrl ? (
                                // 서버 미디어 URL 사용
                                <>
                                    {videoUrl && (
                                        <div>
                                            <video controls ref={videoRef} src={videoUrl}
                                                className="w-full max-h-80 md:max-h-full object-contain">
                                                Your browser does not support the video element.
                                            </video>
                                            <audio controls ref={audioRef} src={audioUrl}
                                                className='hidden'
                                            ></audio>
                                            {/* <div>서버 영상</div> */}
                                        </div>
                                    )}
                                    {/* !videoUrl && */}
                                    {!videoUrl && audioUrl && (
                                        <audio controls ref={audioRef} src={audioUrl} className="w-full max-h-20 md:max-h-full object-contain">
                                            Your browser does not support the audio element.
                                        </audio>
                                    )}
                                </>
                            ) : (
                                // 로컬 미디어 URL 사용
                                <>
                                    {fileType.startsWith('audio') ? (
                                        <audio controls src={localMediaUrl} className="w-full max-h-20 md:max-h-full object-contain">
                                            Your browser does not support the audio element.
                                        </audio>
                                    ) : (
                                        <video controls src={localMediaUrl} className="w-full max-h-80 md:max-h-full object-contain">
                                            Your browser does not support the video element.
                                        </video>
                                    )}
                                </>
                            )}
                        </div>
                    </>
                ) : (
                    <div className='flex flex-col items-center justify-center w-full h-full order-2 md:order-1'>
                        <button type='button' className="text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-indigo-600 dark:hover:bg-indigo-700 focus:outline-none dark:focus:ring-indigo-800" onClick={handleFileUploadClick}>파일 선택</button>
                        <span className='font-bold text-sm'> 또는 파일을 여기로 끌어 놓으세요</span>
                    </div>
                )}
            </div>

            <input
                type='file'
                id='fileInput'
                accept="audio/*,video/*"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </div>
    );
};

export default FileUpload;