// src/components/ProgressBar.js

import React from 'react';

const ProgressBar = ({ progress, message }) => {
  // Math.round(x): Math 객체를 이용한 반올림
  const roundedProgress = Math.round(progress)

  return (
    <div className="fixed inset-0 bg-indigo-100 bg-opacity-75 flex items-center justify-center z-50">
      <div className='flex flex-col w-full items-center justify-center'>
        <div className='font-semibold py-8'>{message}</div>
        <div className="w-3/4 bg-gray-200 rounded-full">
          <div
            className="bg-indigo-600 text-xs font-medium text-white text-center p-0.5 leading-none rounded-full"
            style={{ width: `${(roundedProgress)}%` }}
          >
            {(roundedProgress)}%
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
