import React, { useState, useEffect, useRef } from 'react';
import { getGlosotong, editGlosotongFile, uploadGlosotongFile, translateGlosotongFile, synthesisGlosotongFile, editGlosotongFileTranslate } from './ControlConstCode';
import BodyContent from './BodyContent';
import ProgressBar from './ProgressBar';

const ControlBody = ({ glosori_id }) => {
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [originalContent, setOriginalContent] = useState([]);
  const [translatedContent, setTranslatedContent] = useState([]);
  const [language, setLanguage] = useState('kr');
  const [tolanguage, setToLanguage] = useState('en');
  const [isEditing, setIsEditing] = useState(null);
  const [editedMessage, setEditedMessage] = useState('');
  const [editedStartTime, setEditedStartTime] = useState('');
  const [editedEndTime, setEditedEndTime] = useState('');
  const [editingType, setEditingType] = useState('');
  const [showTranslation, setShowTranslation] = useState(false);
  const [showOriginalContent, setShowOriginalContent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileInfo, setFileInfo] = useState({
    name: '',
    size: '',
    duration: '',
    sampleRate: '',
    numberOfChannels: ''
  });

  const [uniqueId, setUniqueId] = useState('');
  const [showSynthesis, setShowSynthesis] = useState(false);  // 합성 가능 여부 확인
  const [arrowStates, setArrowStates] = useState({});
  const [changesInOriginalItem, setChangesInOriginalItem] = useState(false);
  const [originalFsid, setOriginalFsid] = useState('');
  const [translatedFsid, setTranslatedFsid] = useState('');
  const [videoFsid, setVideoFsid] = useState('');
  const [audioFsid, setAudioFsid] = useState('');
  const [severFileType, setServerFileType] = useState('')
  const [videoUrl, setVideoUrl] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [originalStt, setOriginalStt] = useState([]);
  const [translatedStt, setTranslatedStt] = useState([]);
  const inputRef = useRef(null);
  const [isSynthesisComplete, setIsSynthesisComplete] = useState(false);

  // 파일 데이터 가져오는 함수
  const fetchFileData = async (fileName) => {
    try {
      if (fileName) {
        const updatedFiles = await getGlosotong(glosori_id, uniqueId);

        if (!updatedFiles.glosotongs) {
          throw new Error('glosotongs not found in the response');
        }
        const filteredFiles = updatedFiles.glosotongs.filter(glosotong => glosotong.file_name === fileName);
        setFiles(filteredFiles);

        const sttData = updatedFiles[`stt_${language}`].stt;
        const originalData = [];
        const translatedData = [];

        for (const [key, value] of Object.entries(sttData)) {
          originalData.push({
            key: key.toString(),
            startTime: value.start_time,
            endTime: value.end_time,
            message: value.text
          });
          if (updatedFiles[`stt_${tolanguage}`] && updatedFiles[`stt_${tolanguage}`].stt[key]) {
            translatedData.push({
              key: key.toString(),
              startTime: updatedFiles[`stt_${tolanguage}`].stt[key].start_time,
              endTime: updatedFiles[`stt_${tolanguage}`].stt[key].end_time,
              message: updatedFiles[`stt_${tolanguage}`].stt[key].text
            });
          } else {
            translatedData.push({
              key: key.toString(),
              startTime: value.start_time,
              endTime: value.end_time,
              message: ''
            });
          }
        }
        setOriginalContent(originalData);
        setTranslatedContent(translatedData);
      } else {
        console.error('glosotongs not found in the response');
      }
    } catch (error) {
      console.error('파일을 가져오는 중 오류가 발생했습니다....! fetchFileData error', error);
    }
  };

  // useEffect를 사용하여 컴포넌트가 렌더링될 때 fetchFileData를 호출합니다.
  useEffect(() => {
    if (glosori_id && fileName) {
      fetchFileData(fileName);
    }
  }, [glosori_id, fileName]);

  // 유틸리티 함수
  const formatTimeForDB = (time) => {
    // 00:00:00.0 형식을 00:00:00,000 형식으로 변환
    const [h, m, s] = time.split(':');
    const secondsParts = s.split('.');
    return `${h || '00'}:${m}:${secondsParts[0]},${secondsParts[1].padEnd(3, '0')}`;
  };

  // 수정 모드에 들어갔을 때 시간 값을 포매팅하는 함수
  const formatTimeForEdit = (time) => {
    return time.replace(',', '.').slice(0, -2);
  };

  // 시간 형식 유효성 검사 함수
  const isValidTimeFormat = (time) => {
    const timeFormatRegex = /^(\d{2}:)?\d{2}:\d{2}.\d{1}$/;
    return timeFormatRegex.test(time);
  };


  //  수정 후 저장 버튼 클릭 핸들러
  const handleSaveButtonClick = async (key, type) => {
    console.log('content type: ', type)
    if (key === null) return;

    // 시간 형식 유효성 검사
    if (type.includes('time') && (!isValidTimeFormat(editedStartTime) || !isValidTimeFormat(editedEndTime))) {
      alert("시간 형식이 올바르지 않습니다. 형식은 00:00:00.0 입니다.");
      return;
    }

    // startTime - endTime 각각 독립적으로 수정될 때 다른 필드의 값을 유지하기 위해 분리
    const updateContent = (content, message, startTime, endTime, fsid) => {
      return content.map(item => {
        if (item.key === key) {
          return {
            ...item,
            message: message !== null ? message : item.message,
            startTime: startTime !== null ? formatTimeForDB(startTime) : item.startTime,
            endTime: endTime !== null ? formatTimeForDB(endTime) : item.endTime,
            fsid: fsid
          };
        }
        return item;
      });
    };
    let newContent, update_request;
    if (type.includes('original')) {
      newContent = updateContent(originalContent, type.includes('content') ? editedMessage : null,
        type.includes('time') ? editedStartTime : null,
        type.includes('time') ? editedEndTime : null,
        originalContent.find(item => item.key === key).fsid);
      setOriginalContent(newContent);

      const updatedItem = newContent.find(item => item.key === key);
      if (!updatedItem) {
        console.error(`key에 해당하는 아이템을 찾을 수 없습니다: ${key}`);
        return;
      }

      update_request = {
        start_time: updatedItem.startTime,
        end_time: updatedItem.endTime,
        text: updatedItem.message,
        fs_id: updatedItem.fsid
      };
      console.log('updated_request in original: ', update_request)
      try {
        console.log('11', glosori_id, uniqueId, language, key, update_request)
        const result = await editGlosotongFile(glosori_id, uniqueId, language, key.toString(), update_request);
        console.log('editGlosotongFile result:', result);


        const updatedFiles = await getGlosotong(glosori_id, uniqueId);
        const updatedSttData = updatedFiles[`stt_${language}`].stt;
        const updatedOriginalContent = Object.entries(updatedSttData).map(([key, value]) => ({
          key: key,
          startTime: value.start_time,
          endTime: value.end_time,
          message: value.text,
          fsid: value.fs_id
        }));

        setOriginalContent(updatedOriginalContent);

      } catch (error) {
        console.error('파일을 수정하는 중 오류가 발생했습니다. handleSaveButtonClick', error);
      }

      // 수정 내용 감지(arrowState)
      const originalItem = originalContent.find(item => item.key === key);
      const hasChangesInOriginalItem = (type.includes('content') && originalItem.message !== editedMessage)
      if (hasChangesInOriginalItem) {
        setArrowStates(prev => ({ ...prev, [key]: true }));
        setChangesInOriginalItem(true);
      } else {
        setChangesInOriginalItem(false);
      }

    } else if (type.includes('translated')) {
      // 번역 콘텐츠 수정
      newContent = updateContent(
        translatedContent,
        type.includes('content') ? editedMessage : null,
        type.includes('time') ? editedStartTime : null,
        type.includes('time') ? editedEndTime : null,
        translatedContent.find(item => item.key === key).fsid
      );
      setTranslatedContent(newContent);

      const updatedItem = newContent.find(item => item.key === key);
      if (!updatedItem) {
        console.error(`key에 해당하는 아이템을 찾을 수 없습니다: ${key}`);
        return;
      }
      // 수정 상태 초기화
      update_request = {
        start_time: updatedItem.startTime,
        end_time: updatedItem.endTime,
        text: updatedItem.message,
        fs_id: updatedItem.fsid
      };

      try {
        await editGlosotongFile(glosori_id, uniqueId, tolanguage, key, update_request);
      } catch (error) {
        console.error('파일을 수정하는 중 오류가 발생했습니다.', error);
      }

      setArrowStates(prev => {
        const newState = { ...prev };
        return newState;
      });
    }

    setIsEditing(null);
    setEditedMessage("");
    setEditedStartTime("");
    setEditedEndTime("");
    setEditingType('');
  };

  // 인식하기(음성파일 -> STT 원본 생성)
  const handleAnalyze = async (event) => {
    console.log(fileInfo)
    // 파일 업로드 (CreateProjectModal과 연결된 코드)
    const file = event.target.files[0];
    if (!file) return;

    // 로딩 시작
    setIsLoading(true);
    setProgress(0);

    // 파일 정보가 없다면 대기
    while (!fileInfo.duration) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    // ProgressBar에 적용하기 윈한 로딩시간 및 인터벌 조정 함수
    const progressDuration = parseFloat(fileInfo.duration);
    console.log('duration 확인: ', progressDuration);
    const tweakDuration = (progressDuration + 20) / 3; // 전체 오디오 시간 조정
    const incrementValue = 100 / tweakDuration;

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + incrementValue;
        if (newProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return newProgress
      });
    }, 1000);

    try {
      console.log("업로드 시작");
      const uploadResponse = await uploadGlosotongFile(glosori_id, language, file);
      console.log('파일 업로드:', uploadResponse);
      // console.log(file);

      if (uploadResponse.status === true) {
        // const fileName = file.name;
        const uniqueId = uploadResponse.file_id;
        console.log('업로드된 파일명(id):', fileName, uniqueId);

        const uploadFile = await getGlosotong(glosori_id, uniqueId);
        console.log('업데이트된 파일 정보:', uploadFile);

        // 파일정보 DB에 저장
        const uploadFileInfo = {
          id: glosori_id,
          file_id: uniqueId,
          file_name: uploadFile.name,
          file_size: uploadFile.size,
          file_duration: uploadFile.duration,
          file_samplerate: uploadFile.sampleRate,
          file_numberofchannels: uploadFile.numberOfChannels,
          file_bitrate: uploadFile.bitRate,
          file_type: uploadFile.file_type,
          fs_id: uploadFile.fs_id,
        };

        // console.log('파일 request: ', uploadFileInfo);

        // 파일 타입에 따른 처리(음성-영상 파일 구분)
        const filetypeForFsid = uploadFile.file_type;
        console.log('filetypeForFsid in ControlBody: ', filetypeForFsid)
        if (filetypeForFsid === "video") {
          // 영상의 fs_id와 STT 음성의 fs_id 저장
          setVideoFsid(uploadFile.fs_id);
          setAudioFsid(uploadFile[`stt_${language}`].fs_id);
          setServerFileType('video');
        } else if (filetypeForFsid === "audio") {
          // STT 음성의 fs_id만 저장
          setAudioFsid(uploadFile[`stt_${language}`].fs_id);
          setServerFileType('audio');
        };

        const originalSttArray = Object.entries(uploadFile[`stt_${language}`].stt).map(([key, value]) => ({ ...value, key }));
        // console.log('originalSttArray: ', originalSttArray);

        // originalContent와 translatedContent에 데이터 저장
        const originalData = originalSttArray.map(item => ({
          key: item.key,
          startTime: item.start_time,
          endTime: item.end_time,
          message: item.text,
          fsid: item.fs_id
        }));
        // console.log('originalData: ', originalData);

        setOriginalFsid(uploadFile[`stt_${language}`].fs_id);
        setOriginalStt(originalSttArray);
        setOriginalContent(originalData);
        setShowOriginalContent(true);
        setUniqueId(uniqueId);
        setFileName(fileName);
        setShowTranslation(false); // 분석 후 처음에는 번역 보기 안보이게 
      } else {
        console.error('업로드된 파일 정보를 가져오지 못했습니다.');
      }
    }
    catch (error) {
      if (error.response) {
        // 서버가 응답한 경우 (4xx, 5xx 상태 코드)
        console.error('서버 응답 오류:', error.response.data);
      } else if (error.request) {
        // 요청이 만들어졌으나 응답을 받지 못한 경우
        console.error('응답 없음:', error.request);
      } else {
        // 요청 설정 중에 오류가 발생한 경우
        console.error('요청 설정 오류:', error.message);
      }
      throw error;
    } finally {
      // 로딩 끝
      setIsLoading(false);
    }
  };


  // changesInOriginalItem 상태 변화 감지 확인용
  // useEffect(() => {
  //   console.log('changesInOriginalItem 상태가 변경되었습니다:', changesInOriginalItem);
  // }, [changesInOriginalItem]);

  // 번역 요청 핸들러
  const handleTranslate = async () => {
    console.log('uniqueId:', uniqueId);
    setChangesInOriginalItem(false);
    console.log('handleTranslate 작동..!');

    if (changesInOriginalItem) {
      setArrowStates(prev => {
        const newState = { ...prev };
        Object.keys(newState).forEach(key => {
          newState[key] = false;
        });
        return newState;
      });
    }
    setIsLoading(true);

    try {
      // 번역 요청
      console.log('Translating content...');
      await translateGlosotongFile(glosori_id, uniqueId, language, tolanguage);
      console.log('Translation completed.');

      // 번역된 데이터 가져오기
      console.log('Fetching translated content...');
      const translatedUploadFile = await getGlosotong(glosori_id, uniqueId);
      console.log('translated data fetched: ', translatedUploadFile);

      // 번역된 데이터 확인
      if (!translatedUploadFile[`stt_${tolanguage}`] || !translatedUploadFile[`stt_${tolanguage}`] || !translatedUploadFile[`stt_${tolanguage}`].stt) {
        throw new Error('번역된 데이터를 가져올 수 없습니다.');
      }

      const translatedSttArray = Object.entries(translatedUploadFile[`stt_${tolanguage}`].stt).map(([key, value]) => ({
        ...value, key,
      }));

      const translatedData = translatedSttArray.map(item => ({
        key: item.key,
        startTime: item.start_time,
        endTime: item.end_time,
        message: item.text,
        fsid: item.fs_id
      }));

      setTranslatedFsid(translatedUploadFile[`stt_${tolanguage}`].fs_id);
      setTranslatedStt(translatedSttArray);
      setTranslatedContent(translatedData);
      setShowTranslation(true);

      setArrowStates(prev => {
        const newState = { ...prev };
        Object.keys(newState).forEach(key => {
          newState[key] = false; // 모든 화살표를 숨김
        });
        return newState;
      });
      setShowSynthesis(true);

    } catch (error) {
      console.error('번역 요청 중 오류 발생:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleSynthesis = async () => {
    setIsLoading(true);

    try {
      // 합성 요청
      console.log('handleSynthesis 작동...!')
      await synthesisGlosotongFile(glosori_id, uniqueId, language, tolanguage);
      const synthesisUploadFile = await getGlosotong(glosori_id, uniqueId);

      const translatedSttArray = Object.entries(synthesisUploadFile[`stt_${tolanguage}`].stt).map(([key, value]) => ({
        ...value, key,
      }));

      const translatedData = translatedSttArray.map(item => ({
        key: item.key,
        startTime: item.start_time,
        endTime: item.end_time,
        message: item.text,
        fsid: item.fs_id
      }));


      setTranslatedFsid(synthesisUploadFile[`stt_${tolanguage}`].fs_id);
      setTranslatedStt(translatedSttArray);
      setTranslatedContent(translatedData);
      setIsSynthesisComplete(true);

    } catch (error) {
      console.log('합성 요청 중 오류 발생: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  // 부분 번역 핸들러
  const handlePartialTranslate = async (key) => {
    try {
      setIsLoading(true);
      const translatedData = await editGlosotongFileTranslate(glosori_id, uniqueId, language, tolanguage, key);
      console.log('translatedData 부분 번역: ', translatedData)

      // // 번역된 데이터 형식 확인
      // if (!translatedData || !translatedData[key] || !translatedData[key].text) {
      //   console.error('올바른 번역 데이터를 받지 못했습니다.', translatedData);
      //   throw new Error('올바른 번역 데이터를 받지 못했습니다.');
      // }

      // 최신 번역된 데이터 가져오기
      const updatedFiles = await getGlosotong(glosori_id, uniqueId);
      const updatedTranslatedData = updatedFiles[`stt_${tolanguage}`].stt;
      console.log('가져온 최신 번역 데이터:', updatedTranslatedData);

      // 전체 translatedContent 배열에서 해당 key를 가진 항목 업데이트
      const updatedTranslatedContent = translatedContent.map(item => {
        if (item.key === key.toString()) {
          console.log('Updating item: ', item);
          const updatedItem = {
            ...item,
            message: updatedTranslatedData[key].text,
            startTime: updatedTranslatedData[key].start_time || item.startTime,
            endTime: updatedTranslatedData[key].end_time || item.endTime,
            fsid: updatedTranslatedData[key].fs_id || ''
          };
          console.log('Updated item: ', updatedItem);
          return updatedItem;
        }
        return item;
      });

      setTranslatedContent(updatedTranslatedContent);
      console.log('updated partial translated: ', updatedTranslatedContent)

      // 화살표 버튼 상태 업데이트
      setArrowStates(prev => {
        const newState = { ...prev };
        delete newState[key];
        return newState;
      });

    } catch (error) {
      console.error('부분 번역 오류 발생:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('translatedContent 변경:', translatedContent);
  }, [translatedContent]);

  return (
    <div className="flex flex-col h-full w-full ">
      {/* {isLoading && <LoadingScreen />} */}
      {isLoading && <ProgressBar progress={progress} />}

      <div className="flex-grow overflow-y-auto relative h-full w-full">
        <BodyContent
          glosori_id={glosori_id}
          uniqueId={uniqueId}
          originalContent={originalContent}
          translatedContent={translatedContent}
          isEditing={isEditing}
          editingType={editingType}
          inputRef={inputRef}
          editedMessage={editedMessage}
          editedStartTime={editedStartTime}
          editedEndTime={editedEndTime}
          handleSaveButtonClick={handleSaveButtonClick}
          setEditedMessage={setEditedMessage}
          setEditedStartTime={setEditedStartTime}
          setEditedEndTime={setEditedEndTime}
          setIsEditing={setIsEditing}
          setEditingType={setEditingType}
          showTranslation={showTranslation}
          handleSynthesis={handleSynthesis}
          handlePartialTranslate={handlePartialTranslate}
          arrowStates={arrowStates}
          setArrowStates={setArrowStates}
          changesInOriginalItem={changesInOriginalItem}
          setChangesInOriginalItem={setChangesInOriginalItem}
          formatTimeForEdit={formatTimeForEdit}
          handleAnalyze={handleAnalyze}
          videoFsid={videoFsid}
          audioFsid={audioFsid}
          severFileType={severFileType}
          handleTranslate={handleTranslate}
          language={language}
          tolanguage={tolanguage}
          setLanguage={setLanguage}
          showSynthesis={showSynthesis}
          setShowSynthesis={setShowSynthesis}
          fileInfo={fileInfo}
          setFileInfo={setFileInfo}
          isSynthesisComplete={isSynthesisComplete}
          setIsSynthesisComplete={setIsSynthesisComplete}
        />
      </div>
    </div>
  )
};

export default ControlBody;