// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './pages/MainPage';
import ControlPage from './pages/ControlPage';
import Header from './components/Header';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        {/* <Header /> */}
        <main>
          <Routes>
            <Route path="/" element={<ControlPage />} />
            <Route path="/voiceai" element={<ControlPage />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;

