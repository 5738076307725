import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://117.16.17.172:8000';

export const healthCheck = async () => {
    try {
        const response = await axios.get(`${API_URL}/`);
        return response.data;
    } catch (error) {
        console.error('상태 확인 중 오류가 발생했습니다!', error);
        throw error;
    }
};


// 파일 정보 요청
export const getGlosotong = async (glosori_id, file_id) => {
    try {
        const response = await axios.get(`${API_URL}/glosotong/get/${glosori_id}/${file_id}`);
        return response.data;
    } catch (error) {
        console.error('glosotong 데이터를 가져오는 중 오류가 발생했습니다!', error);
        throw error;
    }
};


// 파일을 DB에 업로드
export const uploadGlosotongFile = async (glosori_id, language, file) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
        // const formData = new FormData();
        // formData.append('file', file);
        console.log("파일 업로드 요청 전송 중..."); // 디버깅 로그
        const response = await axios.post(`${API_URL}/glosotong/upload/${glosori_id}/${language}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        console.log("파일 업로드 응답:", response); // 응답 로그
        return response.data;
    } catch (error) {
        console.error('파일을 업로드하는 중 오류가 발생했습니다!', error);
        throw error;
    }
};


// Translate된 내용 가져오기
export const translateGlosotongFile = async (glosori_id, file_id, language, tolanguage) => {
    try {
        const response = await axios.patch(`${API_URL}/glosotong/translate/${glosori_id}/${file_id}/${language}/${tolanguage}`);
        return response.data;
    } catch (error) {
        console.error('파일을 번역하는 중 오류가 발생했습니다!', error);
        throw error;
    }
};

// STT된 가져온 파일 수정하기
export const editGlosotongFile = async (glosori_id, file_id, language, index, stt_content) => {
    try {
        const response = await axios.patch(`${API_URL}/glosotong/update/${glosori_id}/${file_id}/${language}/${index}`, stt_content);
        return response.data;
    } catch (error) {
        console.error('파일을 수정하는 중 오류가 발생했습니다!', error);
        throw error;
    }
};

// STT된 가져온 파일 수정한 것에 맞춰서 부분 번역하기
export const editGlosotongFileTranslate = async (glosori_id, file_id, language, tolanguage, index) => {
    try {
        const response = await axios.patch(`${API_URL}/glosotong/translatepartial/${glosori_id}/${file_id}/${language}/${tolanguage}/${index}`);
        console.log('부분 번역: ', response.data)
        return response.data;
    } catch (error) {
        console.error('파일을 수정하는 중 오류가 발생했습니다!', error);
        throw error;
    }
};


// 합성하기
export const synthesisGlosotongFile = async (glosori_id, file_id, language, tolanguage) => {
    try {
        const response = await axios.post(`${API_URL}/glosotong/synthesis/${glosori_id}/${file_id}/${language}/${tolanguage}`);
        console.log('합성하기: ', response.data)
        return response.data;
    } catch (error) {
        console.error('파일을 수정하는 중 오류가 발생했습니다!', error);
        throw error;
    }
};

// 파일 내보내기(다운)
export const downloadGlosotongFile = async (glosori_id, file_id, download_stt_list, download_stt_extension, download_audio_list) => {
    try {
        const response = await axios.post(`${API_URL}/glosotong/download/${glosori_id}/${file_id}`, {
            download_stt_list: download_stt_list,
            download_stt_extension: download_stt_extension,
            download_audio_list: download_audio_list
        }, {
            responseType: 'blob',
        })
        return response.data;
    } catch (error) {
        console.error('파일을 다운로드하는 중 오류가 발생했습니다!', error);
        throw error;
    }
};


// fs 파일 요청
export const fsdownloadGlosotongFile = async (glosori_id, fs_id) => {
    try {
        const response = await axios.get(`${API_URL}/glosotong/fsdownload/${glosori_id}/${fs_id}`, {
            responseType: 'blob',
        })
        console.log('파일다운: ', response.data)
        return response.data;
    } catch (error) {
        console.error('파일을 다운로드하는 중 오류가 발생했습니다!', error);
        throw error;
    }
};