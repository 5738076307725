// src/components/Header.js
import React, { useState } from 'react';
// import GlosoriaiLogo from '../assets/images/glosoriai_logo.gif';
// import HoverLogo from '../assets/images/glosoriai_png.png';
import GlosoriaiLogo from '../assets/images/logo-vid.gif';
import HoverLogo from '../assets/images/logo_ori.png';
// import GlosoriaiLogo from '/src/assets/images/glosoriai_logo.gif';

const Header = ({ sidebarOpen, toggleSidebar }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  }

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }

  return (
    <div>
      <nav className="flex items-center justify-between bg-white shadow-md p-4 md:px-20">
        {/* Logo */}
        <div className="flex items-center relative"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <img
            src={GlosoriaiLogo}
            alt="GLOSORI.AI"
            className={`h-8 w-auto mr-2 transition-opacity duration-300 ease-in-out ${isHovered ? 'opacity-0' : 'opacity-100'}`} />
          <img
            src={HoverLogo}
            alt="GLOSORI.AI"
            className={`h-8 w-auto mr-2 transition-opacity duration-300 ease-in-out absolute top-0 left-0 ${isHovered ? 'opacity-100' : 'opacity-0'}`} />
        </div>

        {/* Page Title */}
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <button
            data-collapse-toggle="navbar-dropdown"
            type="button"
            class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-dropdown"
            aria-expanded={isMobileMenuOpen}
            onClick={toggleMobileMenu}>
            <span class="sr-only">Open main menu</span>
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
          <div
            class={`${isMobileMenuOpen ? 'block' : 'hidden'} w-full md:block md:w-auto`}
            id="navbar-dropdown">
            <ul class="flex flex-col items-center justify-center p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li class='relative group'>
                <button
                  id="dropdownNavbarLink"
                  data-dropdown-toggle="dropdownNavbar"
                  class="flex items-center justify-between w-full py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
                >
                  <span class='flex-1 text-center font-bold'>더빙하기</span>
                  <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                  </svg>
                </button>
                <div class="absolute w-full h-2 bg-transparent"></div>
                <div
                  id="dropdownNavbar"
                  className={`${isDropdownOpen ? 'block' : 'hidden'
                    } absolute top-full mt-2 z-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600 group-hover:block`}
                >
                  <ul
                    class="py-2 text-sm text-gray-700 dark:text-gray-400"
                    aria-labelledby="dropdownLargeButton">
                    <li>
                      <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">한국어 ➡️ 영어</a>
                    </li>
                    <li>
                      <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">영어 ➡️ 한국어</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a href="#" class="block py-2 px-3 text-white font-bold bg-blue-700 rounded md:bg-transparent md:text-black md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent" aria-current="page">딥보이스</a>
              </li>
            </ul>
          </div>
        </div>

        {/* Buttons */}
        <div className="hidden md:flex items-center space-x-2">
          <button type='button'
            class="text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm py-2 px-3 mx-3 dark:bg-indigo-600 dark:hover:bg-indigo-700 focus:outline-none dark:focus:ring-indigo-800">로그인</button>
        </div>
      </nav>
    </div>
  );
};

export default Header;

