import React, { useState, useRef, useEffect } from 'react';
import ControlBody from '../components/control/ControlBody';
import Header from '../components/Header';

const ControlPage = () => {
  const glosori_id = 'admin@glosori.com';

  return (
    <div className="flex flex-col h-screen bg-slate-50">
      <Header />
      <main className="flex-grow overflow-hidden">
        <ControlBody
          glosori_id={glosori_id}
        />
      </main>
    </div>
  );
};

export default ControlPage;