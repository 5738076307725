import React, { useRef, useState, useEffect } from 'react';
import ButtonBox from './ButtonBox';
import FileUpload from './FileUpload';

const FileManager = ({ glosori_id, uniqueId, handleAnalyze, handleTranslate, handleSynthesis, originalContent, translatedContent, showSynthesis, setShowSynthesis, fileInfo, setFileInfo, language, tolanguage, videoFsid, audioFsid, severFileType, isSynthesisComplete, setIsSynthesisComplete }) => {

    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileType, setFileType] = useState('');

    useEffect(() => {
        if (selectedFile) {
            const audio = document.createElement('audio');
            audio.src = URL.createObjectURL(selectedFile);

            audio.onloadedmetadata = () => {
                const context = new (window.AudioContext || window.webkitAudioContext)();
                const reader = new FileReader();
                reader.onload = function () {
                    context.decodeAudioData(reader.result, (buffer) => {
                        const bitRate = (selectedFile.size * 8) / audio.duration;

                        setFileInfo({
                            name: selectedFile.name,
                            size: (selectedFile.size / (1024 * 1024)).toFixed(2), // MB로 변환
                            duration: audio.duration.toFixed(2),
                            sampleRate: buffer.sampleRate,
                            numberOfChannels: buffer.numberOfChannels,
                            bitRate: bitRate.toFixed(2)
                        });
                    });
                };
                reader.readAsArrayBuffer(selectedFile);
            };
        }
    }, [selectedFile, setFileInfo]);

    const handleFileUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files ? event.target.files[0] : event;
        if (file) {
            // 파일 타입 유효성 검사
            const validFileTypes = ['audio', 'video'];
            const fileType = file.type.split('/')[0];
            if (!validFileTypes.includes(fileType)) {
                alert('오디오 또는 비디오 파일만 업로드할 수 있습니다.');
                return;
            }
            setSelectedFile(file);
            setFileType(file.type);
        }
    }

    const handleDropFileChange = (files) => {
        const file = files[0];
        if (file) {
            // 파일 타입 유효성 검사
            const validFileTypes = ['audio', 'video'];
            const fileType = file.type.split('/')[0];
            if (!validFileTypes.includes(fileType)) {
                alert('오디오 또는 비디오 파일만 업로드할 수 있습니다.');
                return;
            }
            setSelectedFile(file);
            setFileType(file.type);
        }
    };



    const handleAnalyzeClick = () => {
        if (selectedFile) {
            handleAnalyze({ target: { files: [selectedFile] } });
        }
    };


    return (
        <div className='flex flex-col'>
            <FileUpload
                glosori_id={glosori_id}
                uniqueId={uniqueId}
                fileInfo={fileInfo}
                selectedFile={selectedFile}
                fileType={fileType}
                handleFileUploadClick={handleFileUploadClick}
                handleFileChange={handleFileChange}
                handleDropFileChange={handleDropFileChange}
                fileInputRef={fileInputRef}
                language={language}
                tolanguage={tolanguage}
                originalContent={originalContent}
                translatedContent={translatedContent}
                videoFsid={videoFsid}
                audioFsid={audioFsid}
                severFileType={severFileType}
                isSynthesisComplete={isSynthesisComplete}
                setIsSynthesisComplete={setIsSynthesisComplete}
            />
            <ButtonBox
                handleFileUploadClick={handleFileUploadClick}
                fileInputRef={fileInputRef}
                handleFileChange={handleFileChange}
                selectedFile={selectedFile}
                handleAnalyzeClick={handleAnalyzeClick}
                handleTranslate={handleTranslate}
                handleSynthesis={handleSynthesis}
                originalContent={originalContent}
                translatedContent={translatedContent}
                showSynthesis={showSynthesis}
                setShowSynthesis={setShowSynthesis}
                fileInfo={fileInfo}
            />
        </div>
    );
};

export default FileManager;