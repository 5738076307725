// components/control/ExportFileModal.jsx (내보내기/파일 다운로드 모달창)

import React from 'react';

const ExportFileModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  // children: ExportingButton에서 내보낸 html -> 💡수정하기??
  // children 사용시 동적관리 가능

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50"
      onClick={handleBackgroundClick}>
      <div className="flex flex-col bg-white rounded-lg p-6 w-auto relative mb-92 lg:mr-16">
        {children}
        <button
          // className="absolute top-4 right-4 text-gray-600"
          className="absolute top-4 right-4 text-gray-600"
          onClick={onClose}
        >
          {/* <svg className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"/>
          </svg> */}

        </button>
      </div>
    </div>
  );
};

export default ExportFileModal;
