import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import '../../App.css';
import FileManager from './FileManager';
import { getGlosotong, fsdownloadGlosotongFile } from './ControlConstCode';

const BodyContent = ({
  glosori_id,
  uniqueId,
  originalContent,
  translatedContent,
  isEditing,
  editingType,
  inputRef,
  editedMessage,
  editedStartTime,
  editedEndTime,
  handleSaveButtonClick,
  setEditedMessage,
  setEditedStartTime,
  setEditedEndTime,
  setIsEditing,
  setEditingType,
  showTranslation,
  handleSynthesis,
  handlePartialTranslate,
  arrowStates,
  setArrowStates,
  changesInOriginalItem,
  setChangesInOriginalItem,
  handleAnalyze,
  videoFsid,
  audioFsid,
  severFileType,
  handleTranslate,
  language,
  tolanguage,
  setLanguage,
  showSynthesis,
  setShowSynthesis,
  fileInfo,
  setFileInfo,
  isSynthesisComplete,
  setIsSynthesisComplete

}) => {

  const [audioUrl, setAudioUrl] = useState(null);
  const audioRef = useRef(null);
  const [playingKey, setPlayingKey] = useState(null);
  const [speakerType, setSpeakerType] = useState('');

  // 시간 입력 필드 포매팅 함수
  const formatTimeInput = (value) => {
    // 숫자 아닌 문자 제거
    const numbersOnly = value.replace(/[^\d]/g, '');

    // 7자리 제한이라서 100시간 미만 가능
    if (numbersOnly.length > 7) {
      return formatTimeInput(numbersOnly.slice(0, 7));
    }

    if (numbersOnly.length <= 1) {
      return `0.${numbersOnly}`;
    } else if (numbersOnly.length <= 3) {
      return numbersOnly.slice(0, 2) + '.' + numbersOnly.slice(2);
    } else if (numbersOnly.length <= 5) {
      return numbersOnly.slice(0, 2) + ':' + numbersOnly.slice(2, 4) + '.' + numbersOnly.slice(4);
    } else {
      return numbersOnly.slice(0, 2) + ':' + numbersOnly.slice(2, 4) + ':' + numbersOnly.slice(4, 6) + '.' + numbersOnly.slice(6);
    }
  };

  // Enter로 수정 완료 처리
  const handleKeyDown = (event, key, type) => {
    if (event.key === 'Enter') {
      if (type.includes('original')) {
        const originalItem = originalContent.find(item => item.key === key);
        console.log('originalItem.message: ', originalItem.message)
        console.log('editedMessage: ', editedMessage)

        const hasChangesInOriginalItem = (type.includes('content') && originalItem.message !== editedMessage)
        console.log('hasChangesInOriginalItem: ', hasChangesInOriginalItem, arrowStates)

        handleSaveButtonClick(key, type);
        // console.log('originalItem: ', originalItem)

        // 화살표 표시 업데이트
        if (hasChangesInOriginalItem) {
          setArrowStates(prev => ({ ...prev, [key]: true }));
          setChangesInOriginalItem(true);
        } else {
          setChangesInOriginalItem(false);
        }
        console.log('hasChange -> arrowStates: ', arrowStates);
      } else {
        handleSaveButtonClick(key, type);
      }
    }
  };

  // 내용 수정 핸들러
  const handleContentEdit = async (key, type) => {
    setIsEditing(key);
    setEditingType(`${type}-content`);

    try {
      const updatedFiles = await getGlosotong(glosori_id, uniqueId);
      const sttData = type.includes('original')
        ? updatedFiles[`stt_${language}`].stt
        : updatedFiles[`stt_${tolanguage}`].stt;
      const content = sttData[key];

      if (!content) {
        console.error(`key에 해당하는 아이템을 찾을 수 없습니다: ${key}`);
        return;
      }
      setEditedMessage(content.text);
    } catch (error) {
      console.error('수정 모드로 진입시 오류 발생 -> handleEdit error: ', error);
    }
  };

  const handleTimeEdit = (key, type) => {
    setIsEditing(key);
    setEditingType(type);
    const content = type === 'original-time' ? originalContent.find(item => item.key === key) : translatedContent.find(item => item.key === key);
    if (!content) {
      console.error(`key에 해당하는 아이템을 찾을 수 없습니다: ${key}`);
      return;
    }
    setEditedStartTime(formatTimeForEdit(content.startTime));
    setEditedEndTime(formatTimeForEdit(content.endTime));
  };

  const saveTime = (key, type) => {
    handleSaveButtonClick(key, `${type}-time`);
    console.log('saveTime: ', key)
  };

  const saveContent = (key, type) => {
    handleSaveButtonClick(key, `${type}-content`);
    console.log('saveContent: ', key)
  };

  // 시간 변환 포매팅
  function formatTimeForEdit(time) {
    return time ? time.replace(',', '.').slice(0, -2) : '';
  };

  // 시간 변환 포매팅
  function convertTimeFormat(time) {
    return time ? time.replace(',', '.').slice(0, -2) : '';
  };

  // 1번 클릭 시 textarea에 커서가 활성화되도록 설정(기존: 클릭 이벤트-상태 업데이트간의 순서 때문)
  useEffect(() => {
    if (isEditing && editingType.includes('content') && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing, editingType, inputRef]);

  // 수정 모드에서 외부 클릭 시 수정 모드 비활성화
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsEditing(null);
        setEditingType('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef]);

  // 스피커 클릭 이벤트 핸들러
  const handleSpeakerClick = async (key, type, fs_id) => {
    if (!fs_id) {
      console.error('fs_id is null or undefined');
      return;
    }

    try {
      const audioBlob = await fsdownloadGlosotongFile(glosori_id, fs_id);
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(audioUrl);
      setPlayingKey(key);

      if (type.includes('original')) {
        setSpeakerType('original');
      } else if (type.includes('translated')) {
        setSpeakerType('translated');
      }

      if (audioRef.current) {
        audioRef.current.src = audioUrl;
        audioRef.current.play();
      }
    } catch (error) {
      console.error('오디오 파일을 다운로드하는 중 오류가 발생했습니다:', error);
    }
  };

  const handleStopClick = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setPlayingKey(null);
      setSpeakerType('');
    }
  };


  return (
    <div className="flex flex-col w-full h-full overflow-y-auto no-scrollbar bg-indigo-100 p-4">
      <FileManager
        glosori_id={glosori_id}
        uniqueId={uniqueId}
        handleAnalyze={handleAnalyze}
        originalContent={originalContent}
        videoFsid={videoFsid}
        audioFsid={audioFsid}
        severFileType={severFileType}
        handleTranslate={handleTranslate}
        handleSynthesis={handleSynthesis}
        translatedContent={translatedContent}
        language={language}
        tolanguage={tolanguage}
        setLanguage={setLanguage}
        showSynthesis={showSynthesis}
        setShowSynthesis={setShowSynthesis}
        fileInfo={fileInfo}
        setFileInfo={setFileInfo}
        isSynthesisComplete={isSynthesisComplete}
        setIsSynthesisComplete={setIsSynthesisComplete}
      />
      {originalContent.map((msg, index) => (
        //mb-4 생략
        <div key={msg.key} className="flex items-start ">
          <div className="flex flex-col md:flex-row w-full">
            <div className={classNames(
              "w-auto md:w-1/2 p-2 mx-4 md:mx-12 text-left bg-white shadow-md",
              { "rounded-t-lg": index === 0, "rounded-b-lg": index === originalContent.length - 1 }
            )}>
              <div className="text-left mx-2 md:mx-0">
                {isEditing === msg.key && editingType === 'original-time' ? (
                  <div className="flex">
                    <input
                      type="text"
                      value={editedStartTime}
                      onChange={(e) => setEditedStartTime(formatTimeInput(e.target.value))}
                      onKeyDown={(e) => handleKeyDown(e, msg.key, 'original-time')}
                      onFocus={(e) => e.target.select()}
                      className="text-sm bg-gray-100 p-2 rounded-lg border shadow-md mb-2 mr-2"
                      placeholder="00:00:00.0"
                    />
                    <input
                      type="text"
                      value={editedEndTime}
                      onChange={(e) => setEditedEndTime(formatTimeInput(e.target.value))}
                      onKeyDown={(e) => handleKeyDown(e, msg.key, 'original-time')}
                      onFocus={(e) => e.target.select()}
                      className="text-sm bg-gray-100 p-2 rounded-lg border shadow-md mb-2"
                      placeholder="00:00:00.0"
                    />

                  </div>
                ) : (
                  <span className="font-semibold px-2" onClick={() => handleTimeEdit(msg.key, 'original-time')}>
                    <span className='px-2'>{msg.key}</span> [{convertTimeFormat(msg.startTime)} - {convertTimeFormat(msg.endTime)}]
                  </span>
                )}
                {speakerType === 'original' && playingKey === msg.key ? (
                  <span className='px-2 cursor-pointer' onClick={handleStopClick}>⏹️</span>
                ) : (
                  <span className='px-2 cursor-pointer' onClick={() => handleSpeakerClick(msg.key, 'original', msg.fsid)}>🔈</span>
                )}
              </div>
              {isEditing === msg.key && editingType === 'original-content' ? (
                <div className="flex flex-col w-full">
                  <textarea
                    ref={inputRef}
                    className="text-sm bg-indigo-100 p-3 w-full rounded-lg"
                    value={editedMessage}
                    onChange={(e) => setEditedMessage(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, msg.key, 'original-content')}
                  />
                </div>
              ) : (
                <div
                  className={classNames(
                    'text-sm bg-slate-50 p-3 rounded-lg border shadow-md mb-1 cursor-text',
                    'border-slate-200 dark:border-slate-700'
                  )}
                  onClick={() => handleContentEdit(msg.key, 'original')}
                >
                  <div>{msg.message}</div>
                </div>
              )}
            </div>

            {showTranslation && (
              <div className={classNames(
                "flex items-center justify-center",
                { "mx-4": !arrowStates[msg.key], "mx-0": arrowStates[msg.key] }
              )}>
                {arrowStates[msg.key] === true && (
                  <div className="text-2xl cursor-pointer" onClick={() => handlePartialTranslate(msg.key)}>➡️</div>
                )}
              </div>
            )}

            {translatedContent.find(item => item.key === msg.key) && showTranslation && (
              <div className={classNames(
                "w-auto md:w-1/2 p-2 mx-4 md:mx-12 text-left bg-white shadow-md",
                { "rounded-t-lg": index === 0, "rounded-b-lg": index === originalContent.length - 1 }
              )}>
                <div className="text-left mx-2 md:mx-0">
                  {isEditing === msg.key && editingType === 'translated-time' ? (
                    <div className="flex">
                      <input
                        type="text"
                        value={editedStartTime}
                        onChange={(e) => setEditedStartTime(formatTimeInput(e.target.value))}
                        onKeyDown={(e) => handleKeyDown(e, msg.key, 'translated-time')}
                        className="text-sm bg-gray-100 p-2 rounded-lg border shadow-md mb-2 mr-2"
                        placeholder="00:00:00.0"
                      />
                      <input
                        type="text"
                        value={editedEndTime}
                        onChange={(e) => setEditedEndTime(formatTimeInput(e.target.value))}
                        onKeyDown={(e) => handleKeyDown(e, msg.key, 'translated-time')}
                        className="text-sm bg-gray-100 p-2 rounded-lg border shadow-md mb-2"
                        placeholder="00:00:00.0"
                      />
                    </div>
                  ) : (
                    <span className="font-semibold px-2" onClick={() => handleTimeEdit(msg.key, 'translated-time')}>
                      <span className='px-2'>{msg.key}</span> [{convertTimeFormat(translatedContent.find(item => item.key === msg.key).startTime)} - {convertTimeFormat(translatedContent.find(item => item.key === msg.key).endTime)}]
                    </span>
                  )}
                  <span className="text-left mx-2 md:mx-0">
                    {isSynthesisComplete ? (
                      <span>
                        {speakerType === 'translated' && playingKey === msg.key ? (
                          <span className='px-2 cursor-pointer' onClick={handleStopClick}>⏹️</span>
                        ) : (
                          <span className='px-2 cursor-pointer' onClick={() => {
                            const content = translatedContent.find(item => item.key === msg.key);
                            if (!content || !content.fsid) {
                              console.error(`fs_id is null or undefined for key ${msg.key}`);
                              return;
                            }
                            handleSpeakerClick(content.key, 'translated', content.fsid);
                          }}>
                            🔈
                          </span>
                        )}
                      </span>
                    ) : (
                      <span style={{ visibility: 'hidden' }}>🔈</span>
                    )}
                  </span>
                </div>
                {isEditing === msg.key && editingType === 'translated-content' ? (
                  <div className="flex flex-col w-full">
                    <textarea
                      ref={inputRef}
                      className="text-sm bg-indigo-100 p-3 w-full rounded-lg"
                      value={editedMessage}
                      onChange={(e) => setEditedMessage(e.target.value)}
                      onKeyDown={(e) => handleKeyDown(e, msg.key, 'translated-content')}
                    />
                  </div>
                ) : (
                  <div
                    className={classNames(
                      'text-sm bg-slate-50 p-3 rounded-lg border shadow-md mb-1 cursor-text',
                      'border-slate-200 dark:border-slate-700'
                    )}
                    onClick={() => handleContentEdit(msg.key, 'translated')}
                  >
                    <div>{translatedContent.find(item => item.key === msg.key).message}</div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
      <audio ref={audioRef} controls style={{ display: 'none' }} />
    </div>
  );
};

export default BodyContent;