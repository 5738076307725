import React, { useState, useEffect } from 'react';
import ExportFileModal from './ExportFileModal';
import { downloadGlosotongFile } from './ControlConstCode';

const ButtonBox = ({ handleAnalyzeClick, selectedFile, originalContent, handleTranslate, handleSynthesis, translatedContent, showSynthesis, setShowSynthesis, fileInfo, glosori_id, fileName, uniqueId }) => {

    const [isFileInfoAvailable, setIsFileInfoAvailable] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedExtensions, setSelectedExtensions] = useState({
        srt: false,
        txt: false,
    });
    const [selectedLanguages, setSelectedLanguages] = useState({
        kr: false,
        en: false,
    });
    const [selectedAudioFormats, setSelectedAudioFormats] = useState({
        kr: false,
        en: false,
    });

    useEffect(() => {
        const checkFileInfo = () => {
            // 객체의 key값이 존재하기 때문에 length > 0까지만 조회하면 true로 인식 -> value로 조회
            const isAvailable = fileInfo && Object.keys(fileInfo).length > 0 &&
                fileInfo.name && fileInfo.size && fileInfo.duration &&
                fileInfo.sampleRate && fileInfo.numberOfChannels;
            console.log('fileInfo in ButtonBox: ', fileInfo);
            console.log('isFileInfoAvailable: ', isAvailable);
            setIsFileInfoAvailable(isAvailable);
        };
        checkFileInfo();
    }, [fileInfo]);

    const handleTranslateClick = () => {
        handleTranslate();
        // if (translatedContent.length > 0) {
        //     setShowSynthesis(true);
        // }
    };

    const handleSynthesisClick = () => {
        handleSynthesis();
    }

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleDownload = async () => {
        try {
            // 선택된 언어 목록 생성
            const selectedLanguageList = Object.keys(selectedLanguages).filter(lang => selectedLanguages[lang]);
            // 선택된 확장자 목록 생성
            const selectedExtensionList = Object.keys(selectedExtensions).filter(ext => selectedExtensions[ext]);
            // 선택된 오디오 형식 목록 생성
            const selectedAudioFormatList = Object.keys(selectedAudioFormats).filter(format => selectedAudioFormats[format]);

            // 다운로드 요청
            const response = await downloadGlosotongFile(
                glosori_id,
                uniqueId,
                selectedLanguageList,
                selectedExtensionList,
                selectedAudioFormatList
            );

            // 파일 다운로드 처리
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            const zipFileName = `${fileName.replace(/\.[^/.]+$/, '')}_glosori.zip`;

            link.href = url;
            link.setAttribute('download', zipFileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('파일을 다운로드하는 중 오류가 발생했습니다!', error);
        } finally {
            closeModal();
        }
    };

    const handleExtensionChange = (e) => {
        setSelectedExtensions({
            ...selectedExtensions,
            [e.target.value]: e.target.checked,
        });
    };

    const handleLanguageChange = (e) => {
        setSelectedLanguages({
            ...selectedLanguages,
            [e.target.value]: e.target.checked,
        });
    };

    const handleAudioFormatChange = (e) => {
        setSelectedAudioFormats({
            ...selectedAudioFormats,
            [e.target.value]: e.target.checked,
        });
    };


    return (
        <div className='flex flex-wrap justify-center lg:justify-between w-full pt-1 pb-2 px-8 md:px-16 bg-indigo-100'>
            <div className="flex flex-wrap justify-center md:justify-start w-full pt-1 pb-2  bg-indigo-100">
                <div className="grid grid-cols-2 gap-2 md:flex md:flex-wrap md:justify-center md:gap-4">
                    {selectedFile && !isFileInfoAvailable && (
                        <div className="flex justify-center w-full md:w-auto">
                            <button type="button"
                                className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-slate-800 hover:bg-indigo-700 hover:text-white dark:text-white dark:focus:ring-indigo-800">
                                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                    인식하기
                                </span>
                            </button>
                        </div>
                    )}
                    {selectedFile && isFileInfoAvailable && (
                        <div className="flex justify-center w-full md:w-auto">
                            <button type="button"
                                className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-indigo-800 hover:bg-indigo-700 hover:text-white dark:text-white dark:focus:ring-indigo-800"
                                onClick={handleAnalyzeClick}>
                                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                    인식하기
                                </span>
                            </button>
                        </div>
                    )}

                    {isFileInfoAvailable && originalContent.length > 0 && (
                        <div className="flex justify-center w-full md:w-auto">
                            <button
                                type="button"
                                className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-indigo-800 hover:bg-indigo-700 hover:text-white dark:text-white dark:focus:ring-indigo-800"
                                onClick={handleTranslateClick}
                            >
                                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                    번역하기
                                </span>
                            </button>
                        </div>
                    )}

                    {translatedContent.length > 0 && showSynthesis && (
                        <div className="flex justify-center w-full md:w-auto">
                            <button
                                type="button"
                                className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-indigo-800 hover:bg-indigo-700 hover:text-white dark:text-white dark:focus:ring-indigo-800"
                                onClick={handleSynthesisClick}
                            >
                                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                    합성하기
                                </span>
                            </button>
                        </div>
                    )}
                    {selectedFile && originalContent.length > 0 && (
                        <div className="flex justify-center w-full md:w-auto">
                            <button type="button"
                                className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-bold text-gray-900 rounded-lg group bg-indigo-700 hover:bg-indigo-700 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-indigo-300 dark:focus:ring-indigo-800" onClick={openModal}>
                                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-indigo-700 text-white dark:bg-gray-900 rounded-md group-hover:bg-white group-hover:text-black group-hover:font-bold">
                                    다운로드
                                </span>
                            </button>
                        </div>
                    )}
                </div>
            </div>


            {/* 모달 창 */}
            <ExportFileModal isOpen={modalIsOpen} onClose={closeModal}>
                <h2 className="text-xl font-bold mb-4">다운로드</h2>
                <div className="grid grid-cols-3 gap-2 mb-4 items-center justify-center">
                    <label className="block text-gray-700 m-2 px-6">파일 형식</label>
                    <div className="flex text-left pl-2">
                        <label >
                            <input
                                className='mx-1'
                                type="checkbox"
                                value="txt"
                                checked={selectedExtensions.txt}
                                onChange={handleExtensionChange}
                            />
                            .txt
                        </label>
                    </div>
                    <div className="flex text-left pl-2">
                        <label >
                            <input
                                className='mx-1'
                                type="checkbox"
                                value="srt"
                                checked={selectedExtensions.srt}
                                onChange={handleExtensionChange}
                            />
                            .srt
                        </label>
                    </div>
                    <label className="block text-gray-700 m-2 px-6">언어</label>
                    <div className="flex flex-col text-left pl-2">
                        <label>
                            <input
                                className='mx-1'
                                type="checkbox"
                                value="kr"
                                checked={selectedLanguages.kr}
                                onChange={handleLanguageChange}
                            />
                            한국어
                        </label>
                    </div>

                    <div className="flex flex-col text-left pl-2">
                        <label>
                            <input
                                className='mx-1'
                                type="checkbox"
                                value="en"
                                checked={selectedLanguages.en}
                                onChange={handleLanguageChange}
                            />
                            영어
                        </label>
                    </div>
                </div>
                <div className="flex justify-center">
                    <button type='button'
                        class="text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-indigo-600 dark:hover:bg-indigo-700 focus:outline-none dark:focus:ring-indigo-800"
                        onClick={handleDownload}
                    >
                        다운로드
                    </button>
                    <button type='button'
                        class="text-white bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:ring-slate-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-slate-600 dark:hover:bg-slate-700 focus:outline-none dark:focus:ring-indigo-800"
                        onClick={closeModal}
                    >
                        취소
                    </button>

                </div>
            </ExportFileModal>
        </div>
    );
};

export default ButtonBox;